/*
 * Globals
 */

/* Links */
@import url('https://fonts.googleapis.com/css?family=Crimson+Text');
a,
a:focus,
a:hover {
  color: #fff;
}
img{
  border: 1px solid white;
  width:50px;
  height:70px;
}
/* Custom default button */
.btn-default,
.btn-default:hover,
.btn-default:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritence from `body` */
  background-color: #fff;
  border: 1px solid #fff;
}
.image-responsive{
  width:80px;
}


/*
 * Base structure
 */

html,
body {
  background-color: #000;
  width:100%;
  /*height:100vh;*/

  
  
}
body {
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 3px rgba(0,0,0,.5);
  font-family: Lora,"Helvetica Neue",Helvetica,Arial,sans-serif;
}
#particles-js{
  width: 100%;
  height:100vh;
  background-color: #000;
 background-image:url('../images/backgrounds/iswebstudio.jpg');
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

/* Extra markup and styles for table-esque vertical and horizontal centering */
h1{
  font: 3em "Raleway", sans-serif;

}
.lead{
        font-family: Lora,"Helvetica Neue",Helvetica,Arial,sans-serif;
        color: #fff;
}
.site-wrapper {
  display: table;
  width: 100%;
  height: 100%; /* For at least Firefox */
  min-height: 100%;
  -webkit-box-shadow: inset 0 0 100px rgba(0,0,0,.5);
          box-shadow: inset 0 0 100px rgba(0,0,0,.5);
    background: url('../images/backgrounds/iswebstudio.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
}
.site-wrapper-inner {
  display: table-cell;
  vertical-align: top;
}
.cover-container {
  margin-right: auto;
  margin-left: auto;
}

/* Padding for spacing */
.inner {
  padding: 30px;
}


/*
 * Header
 */
 h2{
  color: #fff;
 }
 
.masthead-brand {
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  float:left;
}

.masthead-nav > li {
  display: inline-block;
}
.masthead-nav > li + li {
  margin-left: 20px;
}
.masthead-nav > li > a {
  padding-right: 0;
  padding-left: 0;
/*  font-size: 16px;*/
  font-weight: bold;
  color: #fff; /* IE8 proofing */
  color: rgba(255,255,255,.75);
  border-bottom: 2px solid transparent;
}
.masthead-nav > li > a:hover,
.masthead-nav > li > a:focus {
  background-color: transparent;
  border-bottom-color: #AA0000;;

  color:#fff;
}
.masthead-nav > .active > a,
.masthead-nav > .active > a:hover,
.masthead-nav > .active > a:focus {
  border-bottom-color: #AA0000;
  color: #fff;

}




@media (min-width: 768px) {
  .masthead-brand {
    float: left;
  }
  .masthead-nav {
    float: right;
  }
}


/*
 * Cover
 */

.cover {
  padding: 0 20px;
}
h1.cover-heading {
  font-size: 3em;
  letter-spacing: 5px;
    font-family: Lora,"Helvetica Neue",Helvetica,Arial,sans-serif;
    /* font-family: 'Raleway', sans-serif;*/
    text-transform:uppercase;;
    color:#fff
}
.dream{
 -webkit-animation-name: dream; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
  animation-name: dream;
  animation-duration: 7s;
  animation-iteration-count: infinite;
}
/* Standard syntax */
@keyframes dream {
  from {color: white;}
  to {background-color: #AA0000;}
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes dream {
  from {background-color: white;}
  to {background-color: #AA0000;}
}
.cover-heading:after{
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 40%;
    height: 1px;
    margin-left: -20%;
    background-color: #AA0000;
  }
.cover .btn-lg {
  padding: 10px 20px;
/*  font-weight: bold;*/
}

.btn,.btn:hover {
  background:#A00;
  border-radius: 0px;
  color: #fff;

}
.btn:hover {

}

/*
 * Footer
 */

.mastfoot {
  color: #999; /* IE8 proofing */
  color: rgba(255,255,255,.5);
}

.mastfoot .inner{
  color: #fff;
}
.mastfoot .inner a{

  text-decoration: none;
}


.glyphicon.glyphicon-envelope,.glyphicon.glyphicon-phone,.glyphicon.glyphicon-home{
  color: #AA0000;
  width: 24px;
  height:14px;
  margin-right:5px;
  margin-left:10px;
}

.facebook{
    display: inline-block;
    background-image: url('images/facebook.png') ;
    background-repeat: no-repeat;
    background-position: center;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right:5px;
    margin-left:10px;
}

.google{
      display: inline-block;
    background-image: url('images/google.png') ;
    background-repeat: no-repeat;
    background-position: center;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right:5px;
    margin-left:10px;
}
/*
 * Affix and center
 */

@media (min-width: 768px) {
  /* Pull out the header and footer */
  .masthead {
    position: fixed;
    top: 0;
  }
  .mastfoot {
    position: fixed;
    bottom: 0;
  }
  /* Start the vertical centering */
  .site-wrapper-inner {
    vertical-align: middle;
  }
  /* Handle the widths */
  .masthead,
  .mastfoot,
  .cover-container {
    width: 100%; /* Must be percentage or pixels for horizontal alignment */
  }
}

@media (min-width: 992px) {
  .masthead,
  .mastfoot,
  .cover-container {
    width: 1100px;
  }
}
