/*typogr*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

html {
  font: 16px/1.5 $sans-serif, "sans-serif";
  font-weight: normal;
  color: #222;
}

body,
form,
input,
textarea,
option,
select,
button {
  font: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 3em;
  font-weight: bold;
  color: $text-color;
  margin-bottom: 2%;
}

h2 {
  font-size: 2em;
  font-weight: bold;
  color: $text-color;
  margin-bottom: 2%;
}

h3 {
  font-weight: bold;
  margin: 1% 0;
  font-size: 1.5em;
}

a {
  text-decoration: none;
  color: $text-color;
}

p {
  margin: 1em 0;
}

.text-color {
  color: $text-color;
}


/*end_typogr*/
