/*reset*/

* {
	padding: 0;
	margin: 0;
}

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

*, *:before, *:after {
	box-sizing: border-box;
}

fieldset {
	border: unset;
}

a {
	color: black;
	cursor: pointer;
	text-decoration: none!important;
	background-color: transparent;
}

li {
	list-style: none;
}

ol li {
	list-style: inherit;
}

input:focus,
textarea:focus {
	outline: none;
}

/*end_reset*/