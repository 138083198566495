@charset "UTF-8";
/*reset*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Crimson+Text");
* {
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  box-sizing: border-box;
}

fieldset {
  border: unset;
}

a {
  color: black;
  cursor: pointer;
  text-decoration: none !important;
  background-color: transparent;
}

li {
  list-style: none;
}

ol li {
  list-style: inherit;
}

input:focus,
textarea:focus {
  outline: none;
}

/*end_reset*/
/* Media queries */
/*typogr*/
html {
  font: 16px/1.5 "Montserrat", sans-serif, "sans-serif";
  font-weight: normal;
  color: #222;
}

body,
form,
input,
textarea,
option,
select,
button {
  font: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 3em;
  font-weight: bold;
  color: #595860;
  margin-bottom: 2%;
}

h2 {
  font-size: 2em;
  font-weight: bold;
  color: #595860;
  margin-bottom: 2%;
}

h3 {
  font-weight: bold;
  margin: 1% 0;
  font-size: 1.5em;
}

a {
  text-decoration: none;
  color: #595860;
}

p {
  margin: 1em 0;
}

.text-color {
  color: #595860;
}

/*end_typogr*/
/* slide right animation  */
.jump-column {
  transition: transform 0.3s, margin 0.3s;
  cursor: pointer;
  /* for this theme */
  background: white;
}

.jump-column:hover {
  transform: scale(1.1) translateY(-100px);
}

.to-left {
  animation-name: toLeft;
  animation-duration: 3s;
  animation-iteration-count: 1;
  position: relative;
}

@keyframes toLeft {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
/* end of slide right animation  */
/* slide left animation  */
.to-right {
  animation-name: toRight;
  animation-duration: 3s;
  animation-iteration-count: 1;
  position: relative;
  overflow: hidden;
}

@keyframes toRight {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
/* end of slide right animation  */
.up-animation {
  animation-name: up;
  animation-duration: 1s;
  animation-iteration-count: 1;
  position: relative;
}

@keyframes up {
  0% {
    opacity: 0;
    transform: translate(0px, -100px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
.down-animation {
  animation-name: down;
  animation-duration: 1s;
  animation-iteration-count: 1;
  position: relative;
}

@keyframes down {
  0% {
    opacity: 0;
    transform: translate(0px, 50px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
.fadeout {
  animation: fadeOutAnimation 2s forwards;
}

.fadein {
  animation: fadeInAnimation 2s forwards;
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.privacy-cookies {
  position: fixed;
  text-align: center;
  bottom: 0;
  z-index: 2;
  background: linear-gradient(#fff, #0afc77);
  color: black;
  padding: 1em;
  width: 100%;
}
.privacy-cookies .privacy-cookies__close {
  font-size: 1.3em;
  cursor: pointer;
  border: 4px solid white;
  background: #ff0000;
  border-radius: 5px 10px;
  padding: 5px 10px;
  color: white;
}
.privacy-cookies .cookies-read-more {
  background-color: #ff0000;
  color: white;
}

.dataTable-top,
.dataTable-bottom {
  padding: 8px 10px;
}

.dataTable-top > nav:first-child,
.dataTable-top > div:first-child,
.dataTable-bottom > nav:first-child,
.dataTable-bottom > div:first-child,
div.dataTables_length {
  float: left;
}

.dataTable-top > nav:last-child,
.dataTable-top > div:last-child,
.dataTable-bottom > nav:last-child,
.dataTable-bottom > div:last-child,
div.dataTables_filter {
  float: right;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  cursor: default !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty:before {
  display: none !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
  top: 50%;
  left: 5px;
  height: 1em;
  width: 1em;
  margin-top: -9px;
  display: block;
  position: absolute;
  color: white;
  border: 0.15em solid white;
  border-radius: 1em;
  box-shadow: 0 0 0.2em #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New", Courier, monospace;
  line-height: 1em;
  content: "+";
  background-color: #31b131;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th.dtr-control:before {
  content: "-";
  background-color: #d33333;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td.dtr-control,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th.dtr-control {
  padding-left: 27px;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th.dtr-control:before {
  left: 4px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  line-height: 14px;
  text-indent: 3px;
}

table.dataTable.dtr-column > tbody > tr > td.dtr-control,
table.dataTable.dtr-column > tbody > tr > th.dtr-control,
table.dataTable.dtr-column > tbody > tr > td.control,
table.dataTable.dtr-column > tbody > tr > th.control {
  position: relative;
  cursor: pointer;
}

table.dataTable.dtr-column > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-column > tbody > tr > th.dtr-control:before,
table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  top: 50%;
  left: 50%;
  height: 0.8em;
  width: 0.8em;
  margin-top: -0.5em;
  margin-left: -0.5em;
  display: block;
  position: absolute;
  color: white;
  border: 0.15em solid white;
  border-radius: 1em;
  box-shadow: 0 0 0.2em #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New", Courier, monospace;
  line-height: 1em;
  content: "+";
  background-color: #31b131;
}

table.dataTable.dtr-column > tbody > tr.parent td.dtr-control:before,
table.dataTable.dtr-column > tbody > tr.parent th.dtr-control:before,
table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  content: "-";
  background-color: #d33333;
}

table.dataTable > tbody > tr.child {
  padding: 0.5em 1em;
}

table.dataTable > tbody > tr.child:hover {
  background: transparent !important;
}

table.dataTable > tbody > tr.child ul.dtr-details {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:first-child {
  padding-top: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:last-child {
  border-bottom: none;
}

table.dataTable > tbody > tr.child span.dtr-title {
  display: inline-block;
  min-width: 75px;
  font-weight: bold;
}

div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em;
}

div.dtr-modal div.dtr-modal-display {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  z-index: 102;
  overflow: auto;
  background-color: #f5f5f7;
  border: 1px solid black;
  border-radius: 0.5em;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
}

div.dtr-modal div.dtr-modal-content {
  position: relative;
  padding: 1em;
}

div.dtr-modal div.dtr-modal-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
  border: 1px solid #eaeaea;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  z-index: 12;
}

div.dtr-modal div.dtr-modal-close:hover {
  background-color: #eaeaea;
}

div.dtr-modal div.dtr-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.6);
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
div.dataTables_wrapper div.dataTables_filter,
div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
  div.dataTables_wrapper div.dataTables_length,
div.dataTables_wrapper div.dataTables_filter,
div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}
/* Paginate*/
.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #333 !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(230, 230, 230, 0.1);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(230, 230, 230, 0.1)), color-stop(100%, rgba(0, 0, 0, 0.1)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
  /* W3C */
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid #111;
  background-color: #585858;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
  /* W3C */
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #2b2b2b;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
  /* W3C */
  box-shadow: inset 0 0 3px #111;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #333;
}

.dataTables_wrapper .dataTables_scroll {
  clear: both;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  -webkit-overflow-scrolling: touch;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
  vertical-align: middle;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
  border-bottom: none;
}

.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable td,
table.dataTable th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}

table.dataTable > thead > tr > th:active,
table.dataTable > thead > tr > td:active {
  outline: none;
}

table.dataTable > thead > tr > th:not(.sorting_disabled),
table.dataTable > thead > tr > td:not(.sorting_disabled) {
  padding-right: 30px;
}

table.dataTable > thead .sorting,
table.dataTable > thead .sorting_asc,
table.dataTable > thead .sorting_desc,
table.dataTable > thead .sorting_asc_disabled,
table.dataTable > thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:before {
  right: 1em;
  content: "↑";
}

table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "↓";
}

table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:after {
  opacity: 1;
}

table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
  opacity: 0;
}

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table thead .sorting:before,
div.dataTables_scrollBody table thead .sorting_asc:before,
div.dataTables_scrollBody table thead .sorting_desc:before,
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}

div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  margin-top: 0 !important;
  border-top: none;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
div.dataTables_wrapper div.dataTables_filter,
div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: center !important;
  }
}
table.dataTable.table-sm > thead > tr > th:not(.sorting_disabled) {
  padding-right: 20px;
}

table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em;
}

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px;
}

table.table-bordered.dataTable {
  border-right-width: 0;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 1px;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
  padding-left: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
  padding-right: 0;
}

html {
  background: #fafbfc;
}

.hidden {
  display: none !important;
}

.disabled {
  pointer-events: none;
  background: #e9ecef !important;
}

.btn:hover {
  filter: brightness(0.9);
}

.hover-darken:hover {
  filter: brightness(0.8);
}

.h-0 {
  height: 0 !important;
}

.border-dashed {
  border: 1px dashed;
}

.cursor-pointer {
  cursor: pointer !important;
}

.hover-primary:hover {
  color: #45af12 !important;
}

.focus-bigger-textarea:focus {
  height: 150px;
  width: 500px;
  max-width: 100%;
}

.btn:disabled {
  opacity: 1 !important;
}

.btn-grey {
  color: #fff;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.slim-select .placeholder {
  background-color: transparent;
  opacity: 1;
  color: black;
  cursor: pointer;
}

.ss-main {
  display: block;
  width: 100%;
  padding: 0.3rem 0.7rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.08);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
}

.ss-main .ss-single-selected {
  border: none;
}

.cursor-text {
  cursor: text !important;
}

.horizontal-scrollable > .row {
  overflow-x: auto;
  white-space: nowrap;
}

/*
 * Globals
 */
/* Links */
a,
a:focus,
a:hover {
  color: #fff;
}

img {
  border: 1px solid white;
  width: 50px;
  height: 70px;
}

/* Custom default button */
.btn-default,
.btn-default:hover,
.btn-default:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritence from `body` */
  background-color: #fff;
  border: 1px solid #fff;
}

.image-responsive {
  width: 80px;
}

/*
 * Base structure
 */
html,
body {
  background-color: #000;
  width: 100%;
  /*height:100vh;*/
}

body {
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  font-family: Lora, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#particles-js {
  width: 100%;
  height: 100vh;
  background-color: #000;
  background-image: url("../images/backgrounds/iswebstudio.jpg");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

/* Extra markup and styles for table-esque vertical and horizontal centering */
h1 {
  font: 3em "Raleway", sans-serif;
}

.lead {
  font-family: Lora, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #fff;
}

.site-wrapper {
  display: table;
  width: 100%;
  height: 100%; /* For at least Firefox */
  min-height: 100%;
  -webkit-box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.5);
  background: url("../images/backgrounds/iswebstudio.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
}

.site-wrapper-inner {
  display: table-cell;
  vertical-align: top;
}

.cover-container {
  margin-right: auto;
  margin-left: auto;
}

/* Padding for spacing */
.inner {
  padding: 30px;
}

/*
 * Header
 */
h2 {
  color: #fff;
}

.masthead-brand {
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  float: left;
}

.masthead-nav > li {
  display: inline-block;
}

.masthead-nav > li + li {
  margin-left: 20px;
}

.masthead-nav > li > a {
  padding-right: 0;
  padding-left: 0;
  /*  font-size: 16px;*/
  font-weight: bold;
  color: #fff; /* IE8 proofing */
  color: rgba(255, 255, 255, 0.75);
  border-bottom: 2px solid transparent;
}

.masthead-nav > li > a:hover,
.masthead-nav > li > a:focus {
  background-color: transparent;
  border-bottom-color: #AA0000;
  color: #fff;
}

.masthead-nav > .active > a,
.masthead-nav > .active > a:hover,
.masthead-nav > .active > a:focus {
  border-bottom-color: #AA0000;
  color: #fff;
}

@media (min-width: 768px) {
  .masthead-brand {
    float: left;
  }
  .masthead-nav {
    float: right;
  }
}
/*
 * Cover
 */
.cover {
  padding: 0 20px;
}

h1.cover-heading {
  font-size: 3em;
  letter-spacing: 5px;
  font-family: Lora, "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* font-family: 'Raleway', sans-serif;*/
  text-transform: uppercase;
  color: #fff;
}

.dream {
  -webkit-animation-name: dream; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
  animation-name: dream;
  animation-duration: 7s;
  animation-iteration-count: infinite;
}

/* Standard syntax */
@keyframes dream {
  from {
    color: white;
  }
  to {
    background-color: #AA0000;
  }
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes dream {
  from {
    background-color: white;
  }
  to {
    background-color: #AA0000;
  }
}
.cover-heading:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 40%;
  height: 1px;
  margin-left: -20%;
  background-color: #AA0000;
}

.cover .btn-lg {
  padding: 10px 20px;
  /*  font-weight: bold;*/
}

.btn, .btn:hover {
  background: #A00;
  border-radius: 0px;
  color: #fff;
}

/*
 * Footer
 */
.mastfoot {
  color: #999; /* IE8 proofing */
  color: rgba(255, 255, 255, 0.5);
}

.mastfoot .inner {
  color: #fff;
}

.mastfoot .inner a {
  text-decoration: none;
}

.glyphicon.glyphicon-envelope, .glyphicon.glyphicon-phone, .glyphicon.glyphicon-home {
  color: #AA0000;
  width: 24px;
  height: 14px;
  margin-right: 5px;
  margin-left: 10px;
}

.facebook {
  display: inline-block;
  background-image: url("images/facebook.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 10px;
}

.google {
  display: inline-block;
  background-image: url("images/google.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 10px;
}

/*
 * Affix and center
 */
@media (min-width: 768px) {
  /* Pull out the header and footer */
  .masthead {
    position: fixed;
    top: 0;
  }
  .mastfoot {
    position: fixed;
    bottom: 0;
  }
  /* Start the vertical centering */
  .site-wrapper-inner {
    vertical-align: middle;
  }
  /* Handle the widths */
  .masthead,
.mastfoot,
.cover-container {
    width: 100%; /* Must be percentage or pixels for horizontal alignment */
  }
}
@media (min-width: 992px) {
  .masthead,
.mastfoot,
.cover-container {
    width: 1100px;
  }
}
section.login-bg-image {
  background: url("../images/backgrounds/sign-in.svg") no-repeat;
}